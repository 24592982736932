import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const routes = [
	{
		path: 'welcome',
		loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)
	}, {
		path: 'dashboard',
		loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
	}, {
		path: '**',
		redirectTo: 'welcome'
	}
]

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forRoot(routes)
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
